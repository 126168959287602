import {
  clienteLogueado,
  datosSucursal,
  loginWithEmail,
  logout,
} from 'firebase/client';
import Image from 'next/image';
import { useState, useEffect, useContext } from 'react';
import styles from 'styles/Login.module.scss';

import { AdminContext } from 'Context';

export default function Login() {
  const [login, setLogin] = useState({ email: '', pass: '' });
  const [error, setError] = useState(false);

  let { usuarioLogueado, email } = useContext(AdminContext);

  useState(() => {
    email !== null && setLogin({ ...login, email: email });
  }, [email]);

  const handleChange = (e) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    loginWithEmail(login.email, login.pass).then((res) => {
      if (res === false) {
        setError(true);
      } else {
        setError(false);
      }
    });
  };

  return (
    !usuarioLogueado && (
      <div className={styles.login}>
        <div className={styles.logo}>
          <Image
            src='/imgs/logo-login.png'
            layout='responsive'
            width={154}
            height={110}
            alt='logo'
          />
        </div>
        <h1 style={{ color: 'white', marginBottom: '20px', fontWeight: '500' }}>
          ACCESO PRIVADO
        </h1>
        <div
          style={{
            width: '80%',
            minHeight: '100px',
            backgroundColor: 'white',
            borderRadius: '6px',
          }}
        >
          <form onSubmit={handleSubmit} style={{ padding: '10px' }}>
            <label>
              Contraseña:
              <input
                className='input is-normal'
                type='password'
                name='pass'
                placeholder='Escribe tu contraseña'
                onChange={handleChange}
                style={{ border: '1px solid gray', marginTop: '5px' }}
              />
            </label>
            {error && <p>Error de autenticación, verifica tu contraseña.</p>}
            <button>ENTRAR</button>
          </form>
        </div>
      </div>
    )
  );
}
