import Head from 'next/head';
import Image from 'next/image';
import styles from 'styles/Home.module.scss';
import Login from 'components/Login';

export default function Home() {
  return (
    <div className={styles.container}>
      <Head>
        <title>Admin Nilus</title>
        <meta name='description' content='Generated by create next app' />
        <link rel='icon' href='./favicon.png' />
      </Head>
      <main className={styles.main}></main>
    </div>
  );
}
